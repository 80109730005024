<template>
  <div class="odm-flex odm-align-center odm-height-100 odm-width-100">
    <div class="odm-flex odm-vertical-list odm-width-70 odm-white">
      <div class="odm-flex ml15 mt15 odm-width-100 odm-vertical-center odm-align-center">
        <div class="odm-flex odm-vertical-list odm-width-30">
          <div class="odm-flex odm-vertical-center">
            <img  @click="returnClick()" src="./images/logo.png" style="height: 40px;cursor:pointer;">
          </div>
          <div class="odm-flex mt12">
            <div class="xia_dneg" @click="loginClick(0)" v-if="false">
              【微信登录】
            </div>
            <div class="xia_dneg" @click="loginClick(1)" v-if="false">
              【会员登录】
            </div>
            <div class="xia_dneg" @click="loginClick(2)" v-if="false">
              【手机注册】
            </div>
          </div>
        </div>
        <div class="odm-flex odm-align-right odm-width-100">
        <div style="cursor:pointer;" class=" layout-zc-dl odm-flex odm-vertical-center odm-align-center" @click="loginClick(5)" v-if="!islogin">登录</div>
<!--        <div style="cursor:pointer;" class="layout-zc-dl odm-flex odm-vertical-center odm-align-center mr15"  @click="loginClick(2)" v-if="!islogin">注册</div>-->
          <img style="width: 40px;height: 40px;" v-if="islogin" src="../../assets/images/head.png" />
          <div style="cursor:pointer;" class="xia_dneg odm-flex odm-vertical-center" @click="logout" v-if="islogin">
            退出登录
          </div>
        </div>
      </div>
      <div>
      </div>
      <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center mt15 odm-relative">
        <video ref="video"
               controls
               class="video-js vjs-default-skin vjs-big-play-centered"
               controlsList="nodownload"
               oncontextmenu="return false;"
               :disablePictureInPicture="true"
               preload="auto"
              >
        </video>
        <img @click="changeStatue(1)" v-if="playerStatus==0" src="./images/player.png" style="position: absolute; width: 105px;height: 105px;">
        <div v-if="false" class="odm-flex jdt-layout odm-width-100 odm-vertical-center">
          <img @click="changeStatue(0)"  v-if="playerStatus==1" src="./images/zting.png" style="width: 16px;height: 19px;">
          <div class="start-date ml15">{{start_date}}</div>
          <div class="block odm-width-80 ml15 mr15">
            <el-slider v-model="value"></el-slider>
          </div>
          <div class="start-date ml15">{{end_date}}</div>
        </div>

      </div>
      <div class="odm-flex odm-width-100 odm-vertical-center mt12">
        <div class="title-name" v-if="videoData">
          {{videoData.book_name}}
        </div>
        <div class="play-number-layout kcmul-layout ml10">播放量: {{playerNumber}}</div>
        <div class="odm-flex odm-flex-1  odm-align-right">
          <div class="odm-flex" @click="allQp()" v-if="false">
            <div class="layout-yj odm-flex odm-vertical-center odm-align-center">
              <img src="./images/all_screen_image.png" style="width: 11px;height: 11px;"/>
              <div class="all-screen">全屏</div>
            </div>
          </div>
        </div>
      </div>
      <div class="odm-flex mt12 odm-vertical-center">
        <img src="./images/mulu.png" style="width: 12px;height: 10px">
        <div class="kcmul-layout ml5">课程目录</div>
        <div class="layout-yj all-screen ml10">{{videoList.length}}集</div>
      </div>
      <div  class="odm-flex odm-wrap odm-width-100 mb80">
        <div style="cursor:pointer;"  @click="kchengClick(item, index)" v-for="(item,index) in videoList" :key="index"
             class="odm-flex odm-align-center  odm-width-23  odm-vertical-list odm-relative" :class="choosePosition===index?'kc-item-layout-choose':'kc-item-layout'">
          <div class="text-singline2 ml10 mr10 kemu-txt">{{item.course_name }}</div>
          <img class="shikan-img" src="./images/shikan.png" v-if="index==0&&!isVip">
          <img class="shikan-suozhu" src="./images/suozhu.png" v-if="index>0&&!isVip">
        </div>
      </div>
    </div>
    <login-register ref="login_register" @result="result"></login-register>
    <el-dialog
        v-model="dialogVisible"
        title="温馨提示"
        width="30%"
        :before-close="handleClose"
    >
      <span>您的账号还未有开通vip会员请前往APP购买会员</span>
      <template #footer>
      <span class="dialog-footer">
<!--        <el-button @click="dialogVisible = false"></el-button>-->
        <el-button type="primary" @click="dialogVisible = false">
          好的我知道了~
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Video from 'video.js'
import loginRegister from "@/View/components/loginRegister";

export default {
  components:{
    loginRegister
  },
  data () {
    return {
      dialogVisible: false,
      start_date:'00:00',
      end_date:'10:00',
      player:null,
      //0暂停 1播放
      playerStatus:0,
      playerNumber:0,
      videoItem: {},
      book_id:'',
      jshuNumber:0,
      islogin:false,
      videoData:{},
      choosePosition:0,
      videoList: [],
      value: 0,
      options : {
        // playbackRates: [0.5, 1, 1.5,2], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始播放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        //language: 'zh-CN',
        aspectRatio: '16:9', // 播放器的比例。用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，播放器将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          src: '' //url地址
        }],
        poster: '', //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: true,
          // fullscreenToggle: true  //全屏按钮
        },
        errorDisplay: false,
        posterImage: true,
        bigPlayButton : false,
        textTrackDisplay : false,
        userkey:'',
        useData:null,
        isVip:false
      },
    }
  },
  created() {
   this.init();
  },
  mounted() {
  },
  methods: {
    handleClose() {
    },
    returnClick(){
      this.$router.go(-1)
    },
    init(){
      var token = localStorage.getItem('token');
      if(token){
        this.islogin = true;
      }else{
        this.islogin = false;
      }
      console.log('用户token',token)
      this.requestLogin({
        url: this.api.apiArray.QUERY_USE,
        data:{}
      }).then(res=>{
        console.log(res.data.data)
        this.useData = res?.data?.data
      })
      this.book_id = this.$route.query.book_id
      console.log('收到的书籍id',this.book_id)
      this.getBookList(this.book_id)
    },
    result(json){
      console.log(json,'结果')
      if(json == 0){
        this.islogin = false
      }else {
        this.islogin = true;
      }
      this.init();
    },
    logout(){
      this.$refs.login_register.logout()
    },
    loginClick(type){
      this.$refs.login_register.openLogin(type);
    },
    allQp(){
      this.player.enter()
    },
    changeStatue(type){
      this.playerStatus = type;
      if(this.playerStatus == 0){
        this.player.pause();
        console.log('暂停')
      }else{
        this.player.play();
        console.log('播放')
      }
    },
    getBookList(id){
      this.requestNoKey({
        url: this.api.apiArray.VIDEO_LIST,
        data:{
          materialId:id
        }
      }).then(res => {
        console.log( res.data.data, '课程列表接口返回：')
        this.videoList =  res.data.data
        //设置第一个视频默认播放
        this.tongjiSp(this.videoList[this.choosePosition].id)
      })
    },
    kchengClick(item, index){
      this.choosePosition = index
      if(!this.islogin){
        this.loginClick(5);
        return;
      }
      this.isVip = this.isVipMethod()
      if(!this.isVip) {
        this.dialogVisible = true
        return;
      }
      this.tongjiSp(item.id)
    },
    isVipMethod() {
      const today = new Date().toISOString().slice(0, 10);
      if(!this.useData.balanceExpireDate){
        return  false
      } else if(today > this.useData.balanceExpireDate) {
        return false
      }else {
        return true
      }
    },
    tongjiSp(id){
      let _this = this
      this.requestNoKey({
        url: this.api.apiArray.VIDEO_ITEM,
        data:{
          id:id,
          deviceName:"browser",
          deviceType:"web",
          userId:this.useData?.id
        }
      }).then(res => {
        console.log( res?.data?.data, '课程列表接口返回111：')
        const videoItem = res?.data?.data
        this.options.sources[0].src = videoItem.course_video
        this.options.poster = videoItem.course_image
        this.playerNumber = videoItem.size
        if(this.player){
          this.player.src({src:this.options.sources[0].src,type:this.options.sources[0].type});
        }else {
          this.player = Video(this.$refs.video, this.options, function () {
            console.log('初始化完毕')
            this.on("ended",function () {
              console.log('播放结束了')
              _this.playerStatus = 0;
            })
            this.on("pause",function () {
              console.log('播放暂停了')
              _this.playerStatus = 0;
            })
            this.on("play",function () {
              console.log('播放播放了')
              _this.playerStatus = 1;
            })
            this.on("error",function (res) {
              console.log('异常了',res)
              _this.playerStatus = 0;
            })
          });
        }
      })
    },
  },
  // beforeUnmount() {
  //   console.log('离开当前页面了')
  //   if (this.player) {
  //     this.player.dispose()
  //   }
  // }

}
</script>

<style>
.video-js .vjs-progress-holder {
  flex:auto !important;
  transition: all 0.2s;
  height: 8px;
}
.video-js .vjs-play-progress:before {
  font-size: 2em;
  position: absolute;
  right: -0.5em;
  z-index: 1;
}


.start-date{
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFEFE;
}
.jdt-layout{
  position: absolute;
  bottom: 34px;
  left: 23px;
}
.kc-item-layout{
  background: #f5f5f5;
  margin-left: 7px;
  margin-top: 7px;
  font-size: 8px;
  font-weight: 400;
  height: 80px;
  color: #333333;
}
.kc-item-layout-choose{
  background: #44A4CD;
  margin-left: 7px;
  margin-top: 7px;
  font-size: 8px;
  font-weight: 400;
  height: 80px;
  color: white;
}
.xia_dneg{
  opacity: 1;
  font-size: 22px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  color:blue;
  margin-left: 12px;
  letter-spacing: 0px;
}
.title-name{
  opacity: 1;
  font-size: 21px;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.player-number{
  opacity: 1;
  font-size: 12px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
  text-align: left;
  color: #333333;
}
.all-screen{
  opacity: 1;
  font-size: 12px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin-left: 6px;
}
.layout-yj{
  opacity: 1;
  border-radius: 25px;
  background: #44A4CD;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.play-number-layout{
  font-size: 15px;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  text-align: left;
  color: #333333;
}
.kcmul-layout{
  font-size: 11px;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  text-align: left;
  font-weight: 700;
  color: #333333;
}
.shikan-img{
  width: 20px;
  height: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.shikan-suozhu{
  width: 11.5px;
  height: 14px;
  margin-bottom: 5px;
  margin-right: 5px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.layout-zc-dl{
  opacity: 1;
  border-radius: 25px;
  background: #44A4CD;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  margin-left: 5px;
  color: #FFFFFF;
  height: 30px;
}
.kemu-txt{
  font-size: 18px;
}
</style>
