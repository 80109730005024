<template>
    <div v-if="dialogVisible" @click="closeLogin()"  class="odm-position-fixed odm-height-100 odm-width-100 touming-beij">
    </div>
    <!--微信登录-->
<!--    <div v-if="dialogVisible&&typeTag==0"  class="odm-position-fixed odm-height-100 odm-width-100 touming-beij1">-->
<!--      <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center odm-height-100">-->
<!--        <div class="odm-flex odm-relative">-->
<!--          <div class="odm-flex odm-vertical-list wx-login odm-relative">-->
<!--            <div class="odm-flex odm-vertical-center odm-align-center odm-relative">-->
<!--              <img src="../../assets/images/code.png" style="width: 50%; height: auto;margin-top: 81px">-->
<!--              <div class="odm-absolute"><img src="../../assets/images/code_er_qr.png" class="code_qr"></div>-->
<!--            </div>-->
<!--            <div class="sm-code">扫描微信二维码立即登录</div>-->
<!--          </div>-->
<!--          <img @click="closeLogin()" class="img-shanchu" src="../../assets/images/quxiao.png">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--会员登录-->
<!--    <div v-if="dialogVisible&&typeTag==1"  class="odm-position-fixed odm-height-100 odm-width-100 touming-beij1">-->
<!--      <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center odm-height-100">-->
<!--        <div class="odm-flex odm-relative">-->
<!--          <div class="odm-flex odm-vertical-list wx-login">-->
<!--            <div class="zhmi-txt odm-flex odm-vertical-center odm-width-100 odm-align-center">账号密码登录</div>-->
<!--            <div class="odm-flex mt45">-->
<!--              <img src="../../assets/images/zhanghao.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="请输入手机号" class="input-phone" v-model="phone">-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div class="odm-flex mt45">-->
<!--              <img src="../../assets/images/mimakuang.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="请输入密码" class="input-phone" type="password" v-model="password">-->
<!--              <div style="cursor:pointer;" @click="forGet()" class="odm-flex odm-align-right for-get-mima odm-flex-1">忘记密码</div>-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div style="cursor:pointer;" :class="shurOver?'zc-hui-btm-ok':'zc-hui-btm'" class="odm-flex odm-vertical-center odm-align-center" @click="loginMetod">-->
<!--              立即登录-->
<!--            </div>-->
<!--            <div class="odm-flex odm-align-between mt18 mb30">-->
<!--              <div style="cursor:pointer;" class="odm-flex kj-deng" @click="kuaijiLogin()">快捷登录</div>-->
<!--              <div style="cursor:pointer;" class="odm-flex ljzc-btm" @click="gozhuc()">立即注册</div>-->
<!--            </div>-->
<!--            <div v-if="false" class="wx-btm-layout odm-flex odm-vertical-center odm-align-center mt18">-->
<!--              <div class="odm-flex odm-vertical-center" @click="wx_go()">-->
<!--                <img src="../../assets/images/wechat.png" style="width: 31px;height: 31px;">-->
<!--                <div class="wx-text-shaoma">微信扫码登录</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <img @click="closeLogin()" class="img-shanchu" src="../../assets/images/quxiao.png">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--手机注册-->
<!--    <div v-if="dialogVisible&&typeTag==2"  class="odm-position-fixed odm-height-100 odm-width-100 touming-beij1">-->
<!--      <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center odm-height-100">-->
<!--        <div class="odm-flex odm-relative">-->
<!--          <div class="odm-flex odm-vertical-list wx-login">-->
<!--            <div class="zhmi-txt odm-flex odm-vertical-center odm-width-100 odm-align-center">手机注册</div>-->
<!--            <div class="odm-flex mt45">-->
<!--              <img src="../../assets/images/zhanghao.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="请输入手机号" class="input-phone" v-model="phone_zc">-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div class="odm-flex mt34">-->
<!--              <img src="../../assets/images/yanzhengma.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="验证码" class="input-phone" v-model="yzm_input">-->
<!--              <div class="odm-flex odm-align-right for-get-mima odm-flex-1" @click="getMsgMethod()">{{ts_title}}</div>-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div class="odm-flex mt34">-->
<!--              <img src="../../assets/images/mimakuang.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="请输入密码" class="input-phone" type="password" v-model="password_zc">-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div style="cursor:pointer;" @click="startRegistr(0)" class="odm-flex odm-vertical-center odm-align-center mb30" :class="shurOver?'zc-hui-btm-ok':'zc-hui-btm'">-->
<!--               立即注册-->
<!--            </div>-->
<!--          </div>-->
<!--          <img @click="closeLogin()" class="img-shanchu" src="../../assets/images/quxiao.png">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--会员开通-->
<!--    <div v-if="dialogVisible&&typeTag==3" class="odm-position-fixed odm-height-100 odm-width-100 touming-beij1">-->
<!--      <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center odm-height-100">-->
<!--        <div class="odm-flex odm-relative">-->
<!--          <div class="odm-flex odm-relative odm-vertical-list vip_bg odm-align-center odm-vertical-center">-->
<!--            <img @click="closeLogin()" class="img-shanchu1" src="../../assets/images/quxiao.png">-->
<!--            <div class="title-vip">无忧学习.畅享会员</div>-->
<!--            <div class="title-two-vip">为您量身打造精选课程</div>-->
<!--            <div  class="odm-flex mt55 mr15">-->
<!--              <div @click="vipchoose(item,index)" class="odm-flex odm-relative" :class="this_vip==index?'vipchoose_item':'vip-no'" v-for="(item,index) in this.vipList" :key="index">-->
<!--                <img v-if="this_vip==index" src="../../assets/images/cztj.png" class="cztj-layout" >-->
<!--               <div class="odm-flex odm-vertical-list odm-vertical-center odm-align-center">-->
<!--                  <div class="odm-flex mt25" v-if="this_vip==index">-->
<!--                    <div class="yuan-hong">¥{{item.price}}</div>-->
<!--                    <div class="yuan-hong-xiao mt5">元/{{item.name}}</div>-->
<!--                  </div>-->
<!--                 <div class="hong-line" v-if="this_vip==index"></div>-->
<!--                  <div class="odm-flex mt25" v-if="this_vip!=index">-->
<!--                    <div class="huang-hong">¥{{item.price}}</div>-->
<!--                    <div class="huang-hong-xiao mt5">元/{{item.name}}</div>-->
<!--                  </div>-->
<!--                 <div class="huang-line" v-if="this_vip!=index"></div>-->
<!--                 <div class="hui-txt">{{item.desc}}</div>-->
<!--               </div>-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="odm-flex mt55 mb30">-->
<!--              <div class="odm-flex odm-vertical-list odm-vertical-center odm-align-center mr30">-->
<!--                <div class="pay-code odm-flex odm-vertical-center odm-align-center">-->
<!--                  <img src="../../assets/images/code_er_qr.png" style="width: 96px;height: 96px;">-->
<!--                </div>-->
<!--                <div class="odm-flex mt20">-->
<!--                  <img src="../../assets/images/wxpay.png" style="width: 23px;height: 23px;">-->
<!--                  <div class="pay-text">支付宝支付</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="odm-flex odm-vertical-list odm-vertical-center odm-align-center ml30">-->
<!--                <div class="pay-code odm-flex odm-vertical-center odm-align-center">-->
<!--                  <img src="../../assets/images/code_er_qr.png" style="width: 96px;height: 96px;">-->
<!--                </div>-->
<!--                <div class="odm-flex mt20">-->
<!--                  <img src="../../assets/images/alipay.png" style="width: 23px;height: 23px;">-->
<!--                  <div class="pay-text">微信支付</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--  </div>-->
    <!--忘记密码-->
<!--    <div v-if="dialogVisible&&typeTag==4"  class="odm-position-fixed odm-height-100 odm-width-100 touming-beij1">-->
<!--      <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center odm-height-100">-->
<!--        <div class="odm-flex odm-relative">-->
<!--          <div class="odm-flex odm-vertical-list wx-login">-->
<!--            <div class="zhmi-txt odm-flex odm-vertical-center odm-width-100 odm-align-center">修改密码</div>-->
<!--            <div class="odm-flex mt45">-->
<!--              <img src="../../assets/images/zhanghao.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="请输入手机号" class="input-phone" v-model="phone_zc">-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div class="odm-flex mt34">-->
<!--              <img src="../../assets/images/yanzhengma.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="验证码" class="input-phone" v-model="yzm_input">-->
<!--              <div class="odm-flex odm-align-right for-get-mima odm-flex-1" @click="getMsgMethod()">{{ts_title}}</div>-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div class="odm-flex mt34">-->
<!--              <img src="../../assets/images/mimakuang.png" style="width: 20px;height: 20px; margin-left: 52px;">-->
<!--              <input v-on:input="input_shuru()" placeholder="请输入密码" class="input-phone" type="password" v-model="password_zc">-->
<!--            </div>-->
<!--            <div class="line-login"></div>-->
<!--            <div style="cursor:pointer;" @click="startRegistr(1)" class="odm-flex odm-vertical-center odm-align-center mb30" :class="shurOver?'zc-hui-btm-ok':'zc-hui-btm'">-->
<!--              立即修改-->
<!--            </div>-->
<!--          </div>-->
<!--          <img @click="closeLogin()" class="img-shanchu" src="../../assets/images/quxiao.png">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--快捷登录-->
    <div v-if="dialogVisible&&typeTag==5"  class="odm-position-fixed odm-height-100 odm-width-100 touming-beij1">
      <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center odm-height-100">
        <div class="odm-flex odm-relative">
          <div class="odm-flex odm-vertical-list wx-login">
            <div class="zhmi-txt odm-flex odm-vertical-center odm-width-100 odm-align-center">手机快捷登录</div>
            <div class="odm-flex mt45">
              <img src="../../assets/images/zhanghao.png" style="width: 20px;height: 20px; margin-left: 52px;">
              <input  @blur="input_shuru()" placeholder="请输入手机号" class="input-phone" v-model="phone_zc">
            </div>
            <div class="line-login"></div>
            <div class="odm-flex mt34">
              <img src="../../assets/images/yanzhengma.png" style="width: 20px;height: 20px; margin-left: 52px;">
              <input @blur="input_shuru()" placeholder="验证码" class="input-phone" v-model="yzm_input">
              <div class="odm-flex odm-align-right for-get-mima odm-flex-1" @click="getMsgMethod()">{{ts_title}}</div>
            </div>
            <div class="line-login"></div>
            <div style="cursor:pointer;margin-bottom: 30px" @click="startRegistr(2)" class="odm-flex odm-vertical-center odm-align-center" :class="shurOver?'zc-hui-btm-ok':'zc-hui-btm'">
              立即登录
            </div>
            <div v-if="false" style="cursor:pointer;" class="odm-flex odm-vertical-center odm-align-center mt18 mb30" @click="gozhuc()">
              <div class="no-zh">还没有账号？</div>
              <div class="ms-zhuc">马上注册</div>
            </div>
          </div>
          <img @click="closeLogin()" class="img-shanchu" src="../../assets/images/quxiao.png">
        </div>
      </div>
    </div>
    <!--客户联系-->
    <div v-if="dialogVisible&&typeTag==6"  class="odm-position-fixed odm-height-100 odm-width-100 touming-beij1">
    <div class="odm-flex odm-width-100 odm-vertical-center odm-align-center odm-height-100">
      <div class="odm-flex odm-relative">
        <div class="odm-flex odm-vertical-list wx-login odm-vertical-center odm-align-center">
          <div class="zhmi-txt-kf odm-flex odm-vertical-center odm-width-100 odm-align-center">购买请联系客服</div>
         <img src="../../assets/images/kf.png" style="width: 150px;height: 150px; margin-top: 30px">
          <div class="wx-text-shaoma mt18 mb30">客服微信czkf0008</div>
        </div>
        <img  @click="closeLogin()" class="img-shanchu" src="../../assets/images/quxiao.png">
      </div>
    </div>
  </div>
  <el-dialog
      title="温馨提示"
      v-model="dialogVisibleOut"
      width="30%"
      :before-close="handleClose">
    <span>确定退出登录吗？</span>
    <template #footer>
    <span class="dialog-footer">
      <el-button @click="dialogVisibleOut = false">取 消</el-button>
      <el-button type="primary" @click="logoutOk()">确 定</el-button>
    </span>
    </template>
  </el-dialog>
</template>
<script>

import {ElMessage} from "element-plus";

export default {
  data () {
    return {
      dialogVisible: false,
      dialogVisibleOut:false,
      typeTag:0,
      phone:'',
      password:'',
      phone_zc:'',
      yzm_input:'',
      times: 60,
      ts_title:'获取验证码',
      password_zc:'',
      shurOver:false,
      this_vip:0,
      vipList:[],
      userkey:''
    }
  },
  created() {
    // this.getVipList();
  },
  mounted () {
  },
  methods: {
    vipchoose(item,index){
      this.this_vip = index;
    },
    kuaijiLogin(){
      this.shurOver = false;
      this.typeTag = 5;
    },
    forGet(){
      this.shurOver = false;
      this.typeTag = 4;
    },
    input_shuru(){
      console.log('狗日的')
      if (this.phone_zc && this.yzm_input&&this.isPhone(this.phone_zc)) {
        console.log('进来了，，，，')
        this.shurOver = true;
      } else {
        this.shurOver = false;
      }
      // if(this.typeTag == 5){
      //   if(this.phone_zc&&this.yzm_input&&this.isPhone(this.phone_zc)){
      //     this.shurOver = true;
      //   }else{
      //     this.shurOver = false;
      //   }
      // }else if(this.typeTag == 1) {
      //         //会员登录
      //   if(this.phone&&this.password&&this.isPhone(this.phone)){
      //     this.shurOver = true;
      //   }else{
      //     this.shurOver = false;
      //   }
      // }else{
      //   if (this.phone_zc && this.yzm_input&&this.isPhone(this.phone)) {
      //     console.log('进来了，，，，')
      //     this.shurOver = true;
      //   } else {
      //     this.shurOver = false;
      //   }
      // }
    },
    startRegistr(type){
      if(!this.phone_zc){
        ElMessage.error('请输入手机号码');
        return
      }
      if(!this.isPhone(this.phone_zc)){
        ElMessage.error('请输入正确的手机号码');
        return
      }
      if(!this.yzm_input){
        ElMessage.error('请输入验证码');
        return
      }
      if(type!=2) {
        if (!this.password_zc) {
          ElMessage.error('请输入密码');
          return;
        }
      }
      //注册
      if(type==0){
        //开始注册
        this.request({
          url: this.api.apiArray.USERS_REGISTER,
          params: {
            phone:this.phone_zc,
            passwd:this.password_zc,
            code:this.yzm_input
          }
        }).then(res => {
          console.log( res.data, '注册返回：')
          if(res.data.code == 200){
            this.ts_title = '获取验证码'
            clearInterval(this.timer)
            this.times=60
            this.typeTag = 1;
            ElMessage.success({
              message: '注册成功',
              type: 'success'
            });
          }else{
            ElMessage.error(res.data.message);
          }

        })
      }else if(type == 1){
        //修改密码
        this.request({
          url: this.api.apiArray.USERS_CHPWAD,
          params: {
            phone:this.phone_zc,
            passwd:this.password_zc,
            code:this.yzm_input
          }
        }).then(res => {
          console.log( res.data, '注册返回：')
          if(res.data.code == 200){
            this.ts_title = '获取验证码'
            clearInterval(this.timer)
            this.times=60
            ElMessage.success({
              message: '密码修改成功',
              type: 'success'
            });
            this.typeTag = 1;
          }else{
            ElMessage.error(res.data.message);
          }

        })
      }else if(type==2){
        this.requestLogin({
          url: this.api.apiArray.LOGIN_IN,
          data: {
            authType: "1",
            identifier: this.phone_zc,
            credential: this.yzm_input,
          }
        }).then(res => {
          if(res.data.code == 0){
            console.log( res.data.data.token, '登录返回：')
            //登录成功
            localStorage.setItem('token',JSON.stringify(res.data.data.token))
            ElMessage.success({
              message: '登录成功',
              type: 'success'
            });
            this.dialogVisible = false;
            this.$emit('result',1)
          }else{
            ElMessage.warning({
              message: res.data.msg,
              type: 'warning'
            });
          }
        });
      }
    },
    //获取验证码
    getMsgMethod(){
      if(this.ts_title!='获取验证码'){
        return;
      }
      if(!this.phone_zc){
        ElMessage.error('请输入手机号码');
        return;
      }
      if(!this.isPhone(this.phone_zc)){
        ElMessage.error('请输入正确的手机号码');
        return
      }

      this.requestLogin({
        url: this.api.apiArray.SEN_MSG,
        data: {
          telephone: this.phone_zc,
        }
      }).then(res => {
        console.log( res, '验证码返回：')
        if(res.data.code === 0){
          console.log( res.data.code, '验证码返回：')
          ElMessage.success({
            message: '验证码获取成功，打死也不要告诉别人哟！',
            type: 'success'
          });
          this.timer = setInterval(()=>{
            this.times--
            this.ts_title = this.times+'秒'
            if(this.times===0){
              this.ts_title = '获取验证码'
              clearInterval(this.timer)
              this.times=60
            }
          },1000)
        }
      })

    },
    logoutOk(){
      this.dialogVisibleOut = false
      var token = localStorage.getItem('token');
      console.log('用户信息',token)
      localStorage.setItem('token','')
      ElMessage.success({
        message: '登出成功',
        type: 'success'
      });
      this.$emit('result',0)
    },
    /* 是否手机号码*/
    isPhone(value) {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value == '' || value == undefined || value == null) {
        return false
      } else {
        if ((!reg.test(value)) && value != '') {
         return false
        } else {
         return  true
        }
      }
    },
    logout(){
      this.dialogVisibleOut = true;
    },
    gozhuc(){
      this.shurOver = false;
      this.typeTag = 2;
    },
    wx_go(){
      this.typeTag = 0;
    },
    closeLogin(){
      this.dialogVisible = false;
    },
    //获取vip list数据
    getVipList(){
      // this.request({
      //   url: this.api.apiArray.PRODUCTS_GET_LISTS,
      //   params: { type: 1}
      // }).then(res => {
      //   console.log(res, 'getVipList返回：')
      //   this.vipList = res.data.data.lists;
      // })
    },
    loginMetod(){
      if(!this.phone) {
        ElMessage.error('请输入账号');
        return
      }
      if(!this.isPhone(this.phone)){
        ElMessage.error('请输入正确的手机号码');
        return;
      }
      if(!this.password){
        ElMessage.error('请输入账号密码');
        return
      }
      this.request({
        url: this.api.apiArray.USERS_LOGIN,
        params: {
          type:1,
          phone:this.phone,
          passwd:this.password
        }
      }).then(res => {
        console.log( res.data, '登录返回：')
        if(res.data.code == 200){
          //登录成功
          localStorage.setItem('token',JSON.stringify(res.data.data))
          ElMessage.success({
            message: '登录成功',
            type: 'success'
          });
          this.dialogVisible = false;
          this.$emit('result',1)
        }else{
          ElMessage.warning({
            message: res.data.message,
            type: 'warning'
          });
        }
      })
    },
    openLogin(type){
      console.log('yyyy',type)
      this.dialogVisible = true;
      this.typeTag = type;
      if(type == 0) {
        //微信
        console.log('微信')
      }else if(type == 1){
        //会员
        console.log('会员')
      }else if(type == 3 ){
        this.getVipList();
      }
    }
  }
}
</script>

<style>
input {
  border: 0;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #CDCDCD;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #CDCDCD;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #CDCDCD;
}
input::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #CDCDCD;
}
.wx-text-shaoma{
  font-size: 23px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 18px;
  margin-left: 10px;
}
.for-get-mima{
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6A80E9;
  line-height: 21px;
  margin-right: 56px;
}
.input-phone{
  margin-left: 22.5px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
}
.login-tbm{
  height: 50px;
  background: #3DA0F3;
  border-radius: 25px;
  font-size: 23px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FAFAFA;
  line-height: 21px;
  margin-top: 48px;
  margin-left: 38px;
  margin-right: 38px;
}
.kj-deng{
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 21px;
  margin-left: 38px;
}
.ljzc-btm{
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6A80E9;
  line-height: 21px;
  margin-right: 38px;
}
.wx-btm-layout{
  height: 50px;
  border: 2px solid #3DA0F3;
  border-radius: 25px;
  margin-left: 38px;
  margin-right: 38px;
}
.line-login{
  height: 2px;
  background: #F5F5F5;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 13.5px;
}
.img-shanchu{
  width: 36px;
  height: 36px;
  margin-bottom: -30px;
  margin-left: 12px;
  position: relative;
  top: -50px;
}
.img-shanchu1{
  width: 36px;
  height: 36px;
  position: absolute;
  right: -40px;
  top:-40px;
}
.zhmi-txt{
  font-size: 29px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 15px;
  margin-top: 35px;
}
.zhmi-txt{
  font-size: 29px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 15px;
  margin-top: 35px;
}
.zhmi-txt-kf{
  font-size: 29px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 15px;
  margin-top: 35px;
  margin-left: 100px;
  margin-right: 100px;
}
.wx-login{
  background: #FFFFFF;
  opacity: 1;
  z-index: 999;
}
.touming-beij{
  background: #000000;
  opacity: 0.3;
  z-index: 888;
}
.touming-beij1{
  z-index: 999;
}
.zc-hui-btm{
  height: 50px;
  background: #D7D7D7;
  border-radius: 25px;
  font-size: 23px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FAFAFA;
  line-height: 21px;
  margin-top: 45px;
  margin-left: 40px;
  margin-right: 40px;
}
.zc-hui-btm-ok{
  height: 50px;
  background: #3DA0F3;
  border-radius: 25px;
  font-size: 23px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
  margin-top: 45px;
  margin-left: 40px;
  margin-right: 40px;
}
.sm-code{
  font-size: 23px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 18px;
  bottom: 155.5px;
  left: 99px;
  position: relative;
}
.code_qr{
  width: 162px;
  height: 162px;
  position: relative;
}
.title-vip{
  font-size: 23px;
  font-family: Source Han Sans CN Heavy, Source Han Sans CN Heavy-Heavy;
  font-weight: 800;
  text-align: left;
  color: #000000;
  line-height: 18px;
  letter-spacing: 2px;
  margin-top: 50px;
}

.title-two-vip{
  background: #fcbc28;
  border-radius: 14px;
  font-size: 12px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  margin-top: 12px;
}
.vip_bg{
  z-index: 999999;
  background: #FFFFFF;
  opacity: 1;
}
.vipchoose_item{
  opacity: 1;
  background: #ffffff;
  border: 4px solid #ff3d3d;
  border-radius: 18px;
  margin-left: 15px;
}
.vip-no{
  opacity: 1;
  background: #ffffff;
  border: 1px solid #F1F1F1;
  border-radius: 18px;
  margin-left: 15px;
}
.cztj-layout{
  position: absolute;
  right: -2px;
  top: -3px;
  width: 69px;
  height: 18px;
}
.yuan-hong{
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FF3D3D;
  line-height: 14px;
  margin-left: 12px;
}
.yuan-hong-xiao{
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FF3D3D;
  line-height: 14px;
  margin-right: 12px;
}
.huang-hong{
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FF9A18;
  line-height: 14px;
  margin-left: 12px;
}
.huang-hong-xiao{
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FF9A18;
  line-height: 14px;
  margin-right: 12px;
}
.hong-line{
  margin-left: 12px;
  margin-right: 12px;
  height: 1px;
  background:  #FF3D3D;
  margin-top: 6px;
}
.huang-line{
  margin-left: 12px;
  margin-right: 12px;
  height: 1px;
  background: #FF9A18;
  margin-top: 6px;
}
.hui-txt{
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #666666;
  text-align: center;
  max-width: 128px;
  margin-top: 5px;
  margin-bottom: 10px;

}
.pay-code{
  width: 121px;
  height: 121px;
  border: 1px solid #ACACAC;
}
.pay-text{
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-left: 19px;
}
.no-zh{
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 21px;
}
.ms-zhuc{
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6A80E9;
  line-height: 21px;
}
</style>
